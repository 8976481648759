import React from 'react';
import { motion } from 'framer-motion';

const wrapper = {
    visible: { 
        opacity: 1, y: 0,
        transition: {
            when: "beforeChildren",
            delayChildren: 0.1,
        },
    },
    hidden: { 
        opacity: 1, y: 10,
        transition: {
            when: "afterChildren",
        },
    },

  }

const variants = {
    visible: { strokeOpacity: 1, pathLength: 1, pathOffset: 0,
        transition: {duration: 2}  
    },
    hidden: { strokeOpacity: 0.5, pathLength: 0, pathOffset: 1 },
  }

const HomeraBlueprint = (props) => (

    <motion.svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1142.96 1318.11" className={props.className}
    initial="hidden"
    whileInView="visible" 
    viewport={{ once: true, amount: 0 }}            
    variants={wrapper}
  >
      <motion.path strokeDasharray="0 0 5.93 5.93" d="m134.11 406.27 874.74 505.02" 
          fill="none"
          stroke="#aaa" 
          strokeMiterlimit={10} 
          strokeWidth={0.98}      
          variants={variants}
          />
      <motion.path
        strokeDasharray="0 0 5.92 5.92"
        d="m134.15 744.82 582.71 335.06M134.15 665.97l652.57 375.24"
        fill="none"
        stroke="#aaa" 
        strokeMiterlimit={10} 
        strokeWidth={0.98}      
        variants={variants}
      />
      <motion.path strokeDasharray="0 0 5.93 5.93" d="m426.99 237.17 581.86 335.82" 
          fill="none"
          stroke="#aaa" 
          strokeMiterlimit={10} 
          strokeWidth={0.98}      
          variants={variants}      
      />
      <motion.path strokeDasharray="0 0 5.88 5.88" d="m358.78 276.56 650.07 374.73" 
          fill="none"
          stroke="#aaa" 
          strokeMiterlimit={10} 
          strokeWidth={0.98}      
          variants={variants}      
      />
      <motion.path strokeDasharray="0 0 5.93 5.93" d="M1008.85 406.27 206.53 868.9"
          fill="none"
          stroke="#aaa" 
          strokeMiterlimit={10} 
          strokeWidth={0.98}      
          variants={variants}
      />
      <motion.path strokeDasharray="0 0 5.86 5.86" d="m1008.85 744.63-583.7 335.63" 
          fill="none"
          stroke="#aaa" 
          strokeMiterlimit={10} 
          strokeWidth={0.98}      
          variants={variants}      
      />
      <motion.path strokeDasharray="0 0 5.89 5.89" d="m1008.85 666.51-650.07 373.8" 
          fill="none"
          stroke="#aaa" 
          strokeMiterlimit={10} 
          strokeWidth={0.98}      
          variants={variants}      />
      <motion.path
        strokeDasharray="0 0 5.87 5.87"
        d="M717 237.77 140.32 569.36M785.02 277.04 134.15 651.29"
        fill="none"
        stroke="#aaa" 
        strokeMiterlimit={10} 
        strokeWidth={0.98}      
        variants={variants}        
      />
      <motion.path
        strokeDasharray="0 0 5.93 5.93"
        d="M278.82 322.72v672.1M346.74 283.5v749.83M796.22 283.5v749.83M864.14 322.72v672.12"
        fill="none"
        stroke="#aaa" 
        strokeMiterlimit={10} 
        strokeWidth={0.98}      
        variants={variants}        
      />
      <motion.path strokeDasharray="0 0 5.9 5.9" d="M571.48 167.95v974.06" 
          fill="none"
          stroke="#aaa" 
          strokeMiterlimit={10} 
          strokeWidth={0.98}      
          variants={variants}      
          />



      <motion.path d="M571.48 399.28v259.51L346.74 788.53v-259.5l224.74-129.75zM796.22 788.53v-259.5L571.48 399.28v259.5l224.74 129.75z" 
          fill="none"
          stroke="#666" 
          strokeMiterlimit={10} 
          strokeWidth={3}      
          variants={variants}
          
          />
      <motion.path d="m346.74 788.53 224.74-129.75 224.74 129.75-224.74 129.75-224.74-129.75z" 
          fill="none"
          stroke="#666" 
          strokeMiterlimit={10} 
          strokeWidth={3}      
          variants={variants}      
          />
      <motion.path d="M796.22 529.03v259.5L571.48 918.28v78.43l292.66-168.97V489.81l-67.93 39.22ZM278.82 489.81v337.93l292.66 168.97v-78.43L346.76 788.53v-259.5l-67.95-39.22Z" 
          fill="none"      
          stroke="#666" 
          strokeMiterlimit={10} 
          strokeWidth={3}      
          variants={variants}      
      />
      <motion.path d="M864.14 489.81 571.48 320.84 278.82 489.81l67.89 39.22 224.78-129.75 224.74 129.75 67.93-39.22Z"
          fill="none"       
          stroke="#666" 
          strokeMiterlimit={10} 
          strokeWidth={3}      
          variants={variants}
                />
  </motion.svg>
);

export default HomeraBlueprint;
