import * as React from "react"
import Layout from "../components/layout"
import { motion } from "framer-motion"
import { Helmet } from "react-helmet"
import LogoDiamond from "../components/LogoDiamond"
import HomeraBlueprint from "../components/HomeraBlueprint"
import { StaticImage } from "gatsby-plugin-image"
import FeatherIcon from 'feather-icons-react'
import { Suspense, useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { EffectComposer, Bloom } from '@react-three/postprocessing'
import { OrbitControls, Html, Loader} from '@react-three/drei'
import Room from '../components/homera-room'
import FadeUp from "../components/fadeUp"

function Scene() {
  const ref = useRef()
  return (
    <Suspense fallback={null}>   
        <group position-y={0} dispose={null}>
          <Room scale={0.10} position={[-0.5, -0.18, 0]} rotation={[0, Math.PI / 0.69, 0]}/>
        </group>         
  </Suspense>

  )
}


const HomeraPage = () => (
  
  <Layout>

    <Helmet>
      <body className="body-homera"></body>
    </Helmet>

<section className="grid grid-cols-1 lg:grid-cols-2">

    <div className="h-screen/2 lg:h-screen">
        <Canvas flat dpr={[1, 2]} camera={{ fov: 25, position: [50, 50, 90] }}>
          <color attach="background" args={['#e5c3d1']} />
          <directionalLight position={[50, 10, 15]} intensity={3}/>
              <Scene />
              <Html scale={3} rotation={[0, Math.PI / 0.513, 0]} position={[0, 10, -12]} transform occlude>
                <div className="text-xs uppercase text-[#1A535C]">
                <FeatherIcon icon="refresh-cw" size="10" stroke="#1A535C" className="float-left mr-2 mt-1"/>   
                  Click/drag to rotate
                </div>
              </Html>     
          <EffectComposer multisampling={8}>
            <Bloom intensity={0.4} luminanceThreshold={0.8} luminanceSmoothing={0.01} kernelSize={5} />
          </EffectComposer>   
          <OrbitControls
            enablePan={false}
            enableZoom={false}
            minPolarAngle={0}   
            maxPolarAngle={Math.PI / 2} 
            minAzimuthAngle={0}           
            maxAzimuthAngle={Math.PI / 2}      
          />         
        </Canvas>
        <Loader />        
    </div>

    <div className="flex lg:h-screen items-center justify-center order-last lg:order-first bg-owlwhite p-4 lg:p-8 text-center lg:text-left">
      <motion.div className="md:w-2/3"
        initial={{x: -300, opacity: 0}}
        animate={{x: 0, opacity: 1}}
        transition={{duration: 1}}
      >
        <div className="w-4 my-4 mx-auto lg:mx-6 animate-bounce"><LogoDiamond fill="#E63946"/></div>  
        <h1 className="display-1 w-full text-7xl xl:text-8xl text-owlgreen-dark">Homera</h1>
        <motion.hr 
          initial={{width: 0}}
          animate={{width: "100%"}}
          transition={{duration: 1.5}}                  
        />   
        <p className="text-owlgray">Brand identity | Logo design | Website | Marketing materials | Social media | Press releases | Direct marketing | Email campaigns | Marketing strategy</p>
      </motion.div>
    </div>
    
</section>          

<section class="section text-owlwhite">
    <div class="grid sm:grid-cols-2 gap-4">
      <FadeUp className="flex items-center justify-center">
        <div className="w-2/3">
          <h2 className="display-3 border-b py-2">About Homera</h2>
          <p>This ambitious B2B start-up was offering a very new service in a very large industry. They needed a full marketing plan, industry research, social media, print ads and an informative website.</p>
        </div>
      </FadeUp>

      <FadeUp className="flex items-center justify-center">
        <div className="w-2/3">
          <h2 className="display-3 border-b py-2">Our work</h2>
          <p>Bespoke models of housing helped show clearly the concept of the business while still being formal enough for a corporate environment. This carried through all the areas of the marketing plan.</p>
        </div>
      </FadeUp>      
    </div>      
</section>  

<section class="section bg-homerapurple">
    <div class="container">

        <div className="w-100 flex justify-center">
          <StaticImage
            src="../images/homera-logo.svg"
            alt="Homera Logo"
            layout="constrained"
            />          
        </div>

        <h1 className="display-1 text-lg sm:text-4xl text-owlwhite text-center">The creation of a new brand wanting to make an impact in the industry.</h1>

        <FadeUp className="flex items-center justify-center">
          <a href="https://www.homeraltd.co.uk/" className="btn-yellow w-48 py-4 mt-5" target="_blank" rel="noreferrer">
            <div className="display-3">Visit website
            <FeatherIcon icon="external-link" size="24" className="stroke-owlwhite -mt-0.5 ml-3 float-right"/>    
            </div>
          </a>
        </FadeUp>  

        </div>      
</section> 

<section class="section bg-owlwhite">
  <div className="container">
    <div className="flex items-center justify-center">            
        <div className="w-full sm:w-2/3 p-4">
        <HomeraBlueprint />
        </div>  
    </div>  
  </div>
</section>  
            
   
<section className="section">
    <div className="container">

    <div class="flex">
          <StaticImage
            src="../images/tile-homera-cavity.jpg"
            alt="Homera Example 1"
            layout="constrained"
          />
          <StaticImage
            src="../images/tile-homera-box.jpg"
            alt="Homera Example 2"
            layout="constrained"
          />
          <StaticImage
            src="../images/tile-homera-house.jpg"
            alt="Homera Example 3"
            layout="constrained"
          />             
        </div>                  
    </div>
</section>       
      
<section class="section bg-owlwhite">
  <div className="container">
    <div className="flex items-center justify-center">            
        <FadeUp className="w-full sm:w-2/4 p-12">
        <StaticImage
            src="../images/tile-homera-balloon.png"
            alt="Homera Balloon"
            layout="constrained"
          />  
        </FadeUp>  
    </div>  
  </div>
</section>          

<section className="section section-testimonial">
    <div className="container">
        <div className="w-full md:w-1/2 mx-auto">
            <div className="text-right">
                <h1 className="text-lg font-thin sm:text-4xl text-center">"We were blown away by the website and work carried out by 2 Owls. They were very patient with us through the long process of establishing ourselves and the brand." </h1>
                <hr />                
                <h1 className="text-lg sm:text-4xl">- Homera Ltd</h1>
            </div>            
        </div>    
    </div>
</section>             



  </Layout>
)

export default HomeraPage
